<template>
  <q-page class="column wrap justify-start content-stretch items-stretch">
    <div class="bg-white shadow-2 q-px-xl q-pt-xl q-pb-lg">
      <h4 class="row q-mb-xl">
        {{ $t('reporting') }}
      </h4>
      <div class="row no-wrap justify-center content-center items-center filters">
        <div class="row col-11">
          <div class="col-3 q-pr-md">
            <span class="q-title">{{ $t('from') }}</span>
            <m-date-field v-model="parameters.start" format="dd MMMM yyyy" />
          </div>
          <div class="col-3 q-px-md">
            <span class="q-title">To</span>
            <m-date-field v-model="parameters.end" format="dd MMMM yyyy" />
          </div>
          <div class="col-3 q-px-md">
            <span class="q-title">{{ $tc('employee', 2) }}</span>
            <q-select
              v-if="members"
              v-model="parameters.members"
              :options="members"
              :label="$t('filter_by_employee')"
              multiple
              use-input
              emit-value
              map-options
              @filter="filterUsers"
              @selected="resetUsersFilter"
            >
              <template v-slot:option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  v-on="scope.itemEvents"
                >
                  <q-item-section side>
                    <q-checkbox
                      v-model="scope.selected"
                      dense
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label label>
                      {{ scope.opt.label }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="col-3 q-px-md">
            <span class="q-title">{{ $t('travel_content') }}</span>
            <q-select
              v-model="parameters.content_types"
              :options="contentTypes"
              :label="$t('filter_by_travel_type')"
              multiple
              emit-value
              map-options
            >
              <template v-slot:option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  v-on="scope.itemEvents"
                >
                  <q-item-section side>
                    <q-checkbox
                      v-model="scope.selected"
                      dense
                    />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label label>
                      {{ scope.opt.label }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
        <div class="col-1">
          <q-btn
            size="lg"
            class="full-width"
            color="primary"
            @click="getReport"
          >
            {{ $t('apply') }}
          </q-btn>
        </div>
      </div>
      <h5 class="row">
        {{ $t('report_download_includes') }}
      </h5>
      <p class="row">
        {{ $t('report_download_includes_description') }}
      </p>
      <div class="row no-wrap justify-center content-center items-center filters">
        <div v-for="(value, key) in includeOptions" :key="key" class="col">
          <q-checkbox v-model="includes" :val="key" :label="value" />
        </div>
      </div>
    </div>

    <div
      class="shadow-2 q-px-xl q-pt-xl q-pb-md relative-position"
    >
      <q-inner-loading :visible="loading">
        <q-spinner-dots size="60" />
      </q-inner-loading>

      <div v-if="empty" class="row q-pa-lg q-mx-lg">
        <m-empty-state :style="{margin: 'auto'}" icon="receipt">
          {{ $t('no_bookings_found') }}
        </m-empty-state>
      </div>

      <div v-else class="row q-pa-lg">
        <m-group-title class="col-12">
          {{ $t('travel_breakdown') }}
        </m-group-title>

        <div class="row col-12 wrap q-pa-lg">
          <div v-for="(item, type) in overview" :key="type" class="col-3">
            <m-dash-booking-card :type="type" :label="$tc(`content_type.${type}`)" :detail="`${item.text}`" rounded />
          </div>
        </div>

        <m-group-title class="col-12">
          {{ $t('bookings_placed') }}
        </m-group-title>

        <div class="col-12 wrap q-pa-lg">
          <q-card class="bg-white">
            <div class="q-pa-md row bg-white bookings-header">
              <div class="col-2">
                {{ $t('name.short') }}
              </div>
              <div class="col text-center">
                {{ $t('travel_content') }}
              </div>
              <div class="col text-center">
                {{ $t('reference') }}
              </div>
              <div class="col text-center">
                {{ $t('price') }}
              </div>
              <div class="col text-center">
                {{ $tc('traveller') }}
              </div>
              <div class="col text-right">
                {{ $t('date.booked_on') }}
              </div>
              <div class="col text-right">
                {{ $t('date.start') }}
              </div>
              <div class="col text-right">
                {{ $t('date.end') }}
              </div>
            </div>
            <div
              v-for="(booking) in bookings"
              :key="booking.reference"
              class="q-pa-md row bg-white booking-row"
            >
              <div class="col-2">
                {{ booking.name }}
              </div>
              <div class="col text-center">
                {{ booking.type }}
              </div>
              <div class="col text-center">
                {{ booking.reference }}
              </div>
              <div class="col text-center">
                {{ booking.gross.text }}
              </div>
              <div class="col text-center">
                {{ booking.traveller }}
              </div>
              <div class="col text-right">
                {{ booking.booked_at.date | date }}
              </div>
              <div class="col text-right">
                {{ booking.starts_at | date }}
              </div>
              <div class="col text-right">
                {{ booking.ends_at | date }}
              </div>
            </div>
          </q-card>
        </div>
      </div>

      <q-page-sticky
        :offset="[18, 18]"
        position="bottom-right"
      >
        <q-btn
          round
          size="18px"
          @click="getCsv"
        >
          <q-icon name="arrow_downward" />
          <q-tooltip>
            {{ $t('download_csv') }}
          </q-tooltip>
        </q-btn>
      </q-page-sticky>
    </div>
  </q-page>
</template>

<script>
import http from 'api/http'
import date from 'utils/date-time'
import processCsv from 'utils/process-csv'
import { MDashBookingCard, MDateField, MEmptyState, MGroupTitle } from 'components'
import i18n from 'i18n'

export default {
  components: {
    MDashBookingCard,
    MDateField,
    MEmptyState,
    MGroupTitle
  },
  filters: {
    date: value => date.toShortDate(value)
  },
  data () {
    return {
      contentTypes: [],
      bookings: [],
      overview: {},
      members: [],
      membersOptions: [],
      includes: [],
      includeOptions: {
        requester: i18n.t('requester'),
        employee_identifier: i18n.t('employee_identifier'),
        department: i18n.t('department'),
        division: i18n.t('division'),
        cost_centre: i18n.t('cost_centre'),
        reason_for: i18n.t('reason_for_travel'),
        labels: i18n.tc('label.label', 2)
      },
      parameters: {
        members: [],
        content_types: [],
        start: date.subtractFromDate(new Date(), { days: 30 }),
        end: date.addToDate(new Date(), { days: 30 })
      },
      loading: false
    }
  },
  computed: {
    empty () {
      return this.bookings.length === 0
    }
  },
  created () {
    this.getReport()

    if (this.$store.getters.organisation) {
      this.$store.dispatch('members/loadUsersOverview', {
        orgId: this.$store.getters.organisation.slug,
        params: {
          limit: 1000
        }
      })
        .then(response => {
          this.members = response.data.map(member => {
            return {
              label: member.display_name,
              value: member.username
            }
          })
          this.membersOptions = this.members
        })
    }
  },
  methods: {
    getReport () {
      this.loading = true
      const params = {
        ...this.parameters,
        start: date.toCivilDate(this.parameters.start),
        end: date.toCivilDate(this.parameters.end)
      }
      if (this.includes.length > 0) {
        params.include = this.includes
      }
      return http.get('intelligence/reporting', { params })
        .then(response => {
          this.bookings = response.data.bookings.map(booking => {
            const type = this.$tc(`content_type.${booking.type}`)
            return {
              ...booking,
              type
            }
          })
          for (const key in response.data.overview) {
            if (key === 'ridehailing') {
              this.overview.taxi = response.data.overview[key]
            } else {
              this.overview[key] = response.data.overview[key]
            }
          }
          this.loading = false
          this.contentTypes = Object.keys(this.overview).map(item => {
            return {
              label: this.$tc(`content_type.${item}`),
              value: this.$tc(`content_type.${item}`)
            }
          })
        })
    },
    getCsv () {
      const params = {
        ...this.parameters,
        start: date.toCivilDate(this.parameters.start),
        end: date.toCivilDate(this.parameters.end)
      }
      if (this.includes.length > 0) {
        params.include = this.includes
      }
      http.get('intelligence/reporting', { params }, {
        headers: {
          'Accept': 'text/csv'
        }
      }).then(d => {
        processCsv(d, this.$t('mobilleo_report'))
      })
    },
    resetUsersFilter () {
      this.members = this.membersOptions
    },
    filterUsers (val, update) {
      if (val === '') {
        update(() => {
          this.members = this.membersOptions
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.members = this.membersOptions.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>

.booking-row
  font-size 14px
  border-bottom 1px solid #f4f4f4
.bookings-header
  font-size 14px
  font-weight 700
  border-bottom 2px solid #f4f4f4
</style>

<style lang="stylus">
.filters .q-input-target
  color white!important
</style>
